.shopping-bar {
  position: fixed;
  bottom: 0;
  height: 60px;
  width: 100vw;
  display: flex;
  background: lightgrey;
  z-index: 1;
}

.shopping-bar-center {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
}

.shopping-icon {
  font-size: 1.5rem;
}

.cart-btn {
  position: relative;
  cursor: pointer;
}

.cart-items {
  position: absolute;
  top: -8px;
  right: -8px;
  background: green;
  padding: 0 5px;
  border-radius: 30%;
  color: black;
}

.total-amount {
  font-size: 1em;
}

.shopping-btn {
  background: none;
  border: none;
}

.cart-costs {
  text-align: center;
}

.cart-costs h3 {
  font-size: 1.3em;
  margin: 10px 0 0 0;
}
.cart-costs p {
  font-size: 0.8em;
}

/* .shopping-bar a {
  color: black;
} */

.products-here {
  height: 100%;
}

.footer-gap {
  height: 60px;
}
